import { AppState } from "../types/state/AppState";
import {
    searchTransactionsActions as actionTypes,
    constants,
} from "../constants";
import { errorActions } from "./error.actions";
import { Deal } from "../types/amr-pipeline/models/Deal";
import { SearchTermItem } from "../components/amrPipeline/types/SearchTermItem";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { amrPipelineSelector } from '../selectors/amr-pipeline.selector';
import { dealsService } from "../services/deals.service";

type TDispatch = ThunkDispatch<AppState, void, AnyAction>;

let loadSearchLookupTimeout: number | null = null;

export const createSearchTransactionActions = (pipelineType: PipelineType) => {
    function searchTermChange(searchTerm = '', showLookup = true) {
        return (dispatch: TDispatch) => {
            dispatch({
                type: actionTypes.SEARCH_TERM_CHANGE,
                pipelineType,
                searchTerm,
            });

            const trimmedSearchTerm = searchTerm.trim();

            if (showLookup && trimmedSearchTerm.length > constants.searchTermAcceptedLength) {
                dispatch(requestSearchLookup(trimmedSearchTerm));
            } else {
                dispatch(resetSearchLookup());
            }
        };
    }

    function requestSearchLookup(searchTerm: string) {
        return (dispatch: TDispatch) => {
            if (loadSearchLookupTimeout) {
                clearTimeout(loadSearchLookupTimeout);
            }
            dispatch(resetLookupCache());
            dispatch(searchItemsRequesting(true));
            loadSearchLookupTimeout = window.setTimeout(
                () => dispatch(loadSearchLookup(searchTerm)),
                400
            );
        }
    }

    function loadSearchLookup(searchTerm: string, isLoadMore: boolean = false) {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            dispatch(searchItemsRequesting(true));
            try {
                const {
                    searchTransactions: {
                        dealsOffset,
                        classesOffset
                    },
                } = amrPipelineSelector(pipelineType)(getState());
                const deals = await dealsService.getDealsList(searchTerm, dealsOffset);
                const dealsWithClasses = await dealsService.getDealsClassesList(searchTerm, classesOffset);

                const lookupData = deals.map((deal: Deal) => ({
                    ...deal,
                    isDeal: true,
                }));

                dispatch({
                    type: actionTypes.SEARCH_LOOKUP_CACHE,
                    pipelineType,
                    classesLookup: dealsWithClasses,
                    lookupData,
                    searchTerm,
                    isLoadMore,
                });
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            } finally {
                dispatch(searchItemsRequesting(false))
            }
        };
    }

    function loadDealsLookup(searchTerm: string, isLoadMore: boolean = false) {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            dispatch(searchItemsRequesting(true));
            try {
                const {
                    searchTransactions: {
                        dealsOffset,
                    },
                } = amrPipelineSelector(pipelineType)(getState());
                const deals = await dealsService.getDealsList(searchTerm, dealsOffset);

                const lookupData = deals.map((deal: Deal) => ({
                    ...deal,
                    isDeal: true,
                }));

                dispatch({
                    type: actionTypes.SEARCH_ADD_DEALS,
                    pipelineType,
                    lookupData,
                    searchTerm,
                    isLoadMore,
                });
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            } finally {
                dispatch(searchItemsRequesting(false))
            }
        };
    }

    function loadClassesLookup(searchTerm: string, isLoadMore: boolean = false) {
        return async (dispatch: TDispatch, getState: () => AppState) => {
            dispatch(searchItemsRequesting(true));
            try {
                const {
                    searchTransactions: {
                        classesOffset
                    },
                } = amrPipelineSelector(pipelineType)(getState());
                const dealsWithClasses = await dealsService.getDealsClassesList(searchTerm, classesOffset);

                const lookupData = dealsWithClasses.map((deal: Deal) => ({
                    ...deal,
                    isDeal: true,
                }));

                dispatch({
                    type: actionTypes.SEARCH_ADD_CLASSES,
                    pipelineType,
                    lookupData,
                    searchTerm,
                    isLoadMore,
                });
            } catch (e) {
                dispatch(errorActions.criticalError(e));
            } finally {
                dispatch(searchItemsRequesting(false))
            }
        };
    }

    function applyLookupItem(
        label: string,
        dealReferenceName: string,
        classNames?: string[],
    ) {
        return (
            dispatch: TDispatch,
            getState: () => AppState
        ) => {
            const { searchTransactions } = amrPipelineSelector(pipelineType)(getState());

            if (
                label &&
                !searchTransactions.searchTermItems.map(i => i.label).includes(label)
            ) {
                dispatch(addSearchItem({
                    label,
                    referenceName: dealReferenceName,
                    classNames,
                }));

            }
            dispatch(searchTermChange(""));
            dispatch(resetSearchLookup());
        };
    }

    function moveBack() {
        return {
            type: actionTypes.SEARCH_MOVE_BACK,
            pipelineType,
        };
    }

    function moveForward() {
        return {
            type: actionTypes.SEARCH_MOVE_FORWARD,
            pipelineType,
        };
    }

    function addSearchItem(item: SearchTermItem) {
        return {
            type: actionTypes.SEARCH_ITEM_ADD,
            pipelineType,
            item,
        };
    }

    function removeSearchItem(index: number) {
        return {
            type: actionTypes.SEARCH_ITEM_REMOVE,
            pipelineType,
            index,
        };
    }

    function removeCurrentItem() {
        return {
            type: actionTypes.SEARCH_REMOVE_CURRENT_ITEM,
            pipelineType,
        };
    }

    function resetSearchLookup() {
        return {
            type: actionTypes.SEARCH_LOOKUP,
            pipelineType,
            lookupData: [],
            classesLookup: [],
        };
    }

    function searchItemsRequesting(isSearching: boolean) {
        return {
            type: actionTypes.SEARCH_ITEMS_REQUESTING,
            pipelineType,
            isSearching
        }
    }

    function reset() {
        return {
            type: actionTypes.SEARCH_RESET,
            pipelineType,
        };
    }

    function resetLookupCache() {
        return {
            type: actionTypes.SEARCH_RESET_LOOKUP_CACHE,
            pipelineType,
        };
    }

    return {
        searchTermChange,
        applyLookupItem,
        loadSearchLookup,
        moveBack,
        moveForward,
        addSearchItem,
        removeCurrentItem,
        removeSearchItem,
        resetSearchLookup,
        reset,
        resetLookupCache,
        loadDealsLookup,
        loadClassesLookup,
    };
};
